import { getExclusiveTax, getInclusiveTax, setTaxRound } from "../../../helpers/calc";
import { toFixedNoRounding } from "../../../helpers/utils";

export default {
  methods: {
    calculateOrderTax: function () {
      let overallTax = 0;
      for (const item of this.currentOrder.items) {
        const product = this.products.find(prod => prod.id === item.product);
        const isTaxed = product && product.tax_rates && product["tax_rates"].length > 0
          && item.tax_status === 0;
        if (!item.void_status && isTaxed) {
          overallTax += item.tax * item.quantity;
        }
      }
      return parseFloat(toFixedNoRounding(overallTax, 2));
    },
    getItemAllTaxSum: function (item, onlyItemTax = false) {
      let sum = 0;
      if (item["tax_items"]) {
        for (const tax_item of item["tax_items"]) {
          sum += onlyItemTax ? tax_item.tax_value_item : tax_item.tax_total;
        }
      } else {
        console.log(item);
        console.error("getItemAllTaxSum no tax_items");
      }
      return setTaxRound(sum);
    },
    calculateItemTaxItems: function (item) {
      if (!item) throw Error("[calculateItemTax]1 Can not calculate tax as no item provided", item);
      const product = this.products.find(prod => prod.id === item.product);
      if (!product) throw Error("[calculateItemTax] Can not calculate tax as product is missing");
      const tax_items = [];
      const self = this;

      for (const tax_rate of product["tax_rates"]) {
        let modTaxSum = 0;
        let itemTax = 0;
        for (const mod of item["modifiers"].filter(m => m.void_status===false)) {
          if (this.is_tax_inclusive) {
            modTaxSum += setTaxRound(getInclusiveTax(mod.price_original, tax_rate.rate / 100));
          } else {
            modTaxSum += setTaxRound(getExclusiveTax(mod.price_original, tax_rate.rate / 100));
          }
        }
        if (this.is_tax_inclusive) {
          itemTax = setTaxRound(getInclusiveTax((item.price_base - (item.discount+ (item.wholeDiscount < 0 ? (-1*item.wholeDiscount) : item.wholeDiscount))), tax_rate.rate / 100));
        } else {
          itemTax = setTaxRound(getExclusiveTax((item.price_base - (item.discount+(item.wholeDiscount < 0 ? (-1*item.wholeDiscount) : item.wholeDiscount))), tax_rate.rate / 100));
        }
        // for back compatability
        if (tax_rate.id) {
          const calculated_tax_item = {
            local_id: `${self.terminal.id}_${Date.now()}`,
            tax_rate: tax_rate.id,
            tax_rate_value: tax_rate.rate,
            tax_value_item: itemTax,
            tax_value_modifiers: modTaxSum,
            name: tax_rate.name,
            tax_total: setTaxRound((itemTax + modTaxSum))
          };
          tax_items.push(calculated_tax_item);
        }
      }
      return tax_items;
    },
    calculateAllItemTaxes: function () {
      for (const index of this.currentOrder.items.keys()) {
        this.currentOrder.items[index].tax_items = this.currentOrder.items[index].tax_status === 0 ?
          this.calculateItemTaxItems(this.currentOrder.items[index]) : [];
        this.currentOrder.items[index].tax = this.getItemAllTaxSum(this.currentOrder.items[index]);
      }
    }
  }
};
